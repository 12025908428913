import React from "react"
import { DefaultHeader } from "components/headers/default-header"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { config } from "@fortawesome/fontawesome-svg-core"
import { CvBtn } from "components/case_study/cv-btn"
import { StarRates } from "components/case_study/star-rates"
import { FlowNumber } from "components/case_study/flow-number"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheck,
  faArrowDown,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons"
config.autoAddCss = false

// キャンペーン概要
const features = [
  { color: "bg-case_blue", title: "Diffusivity", content: "High" },
  {
    color: "bg-case_orange",
    title: "Lottery Method",
    content: "Instant Win (Instant Drawing)",
  },
  {
    color: "bg-accent",
    title: "Result Confirmation Method",
    content: "DM (Winners only)",
  },
]

// こんな方におすすめ文言
const recommends = [
  { title: "Organize a campaign for the first time" },
  { title: "Low budget for holding campaigns" },
  { title: "Want my tweets to spread" },
  { title: "Want to apply a win filter" },
  { title: "Want to make increasing the number of followers my primary goal" },
  {
    title:
      "Want to send digital gift certificates to eliminate the hassle of sending gifts",
  },
  { title: "Want to distribute our own coupon codes and promote sales" },
]

const NonInstantWinDigitalGift = ({ location }) => {
  return (
    <>
      <DefaultHeader lang="en-US" />
      <Layout lang="en-US">
        <Seo
          pagetitle="Twitter Campaign Case Study - Gift Certificates Automatically Sent with Retweet"
          pagepath={location.pathname}
          pagedesc="To enter the campaign, all you have to do is follow the campaign sponsor's Twitter account and retweet the designated post. This campaign is very effective in increasing Twitter followers and raising awareness."
        />
        <main className="case_study main">
          {/* メインビジュアル */}
          <section className="hero h-auto">
            <div className="inner relative py-5 md:py-8 px-4">
              <h1 className="text-white font-bold md:mb-4 mb-3">
                Follow &amp; Retweet&nbsp;
                <br className="block md:hidden" />
                and send gift certificates&nbsp;
                <br className="block" />
                (Instant Win)
              </h1>
              <p className="text-white md:text-xl text-lg font-bold leading-relaxed">
                To enter, simply follow the event account and retweet the
                designated post.
                <br />
                It can be fully automated, from on-the-spot drawing to immediate
                DM transmission of digital gift certificate codes.
                <br />
                It is very effective in increasing followers.
              </p>
              <div className="fv_img absolute -right-8 top-32">
                <StaticImage
                  layout="fullWidth"
                  alt="Follow &amp; Retweet and send gift certificates (Instant Win)"
                  src="../../../../images/case_study/mv01.png"
                />
              </div>
            </div>
          </section>
          {/* こんな方におすすめです */}
          <section className="recommend bg-secondary">
            <div className="inner py-6 md:pt-10 md:pb-28 px-4">
              <ul className="flex md:mb-8 mb-6 gap-2">
                {features.map((feature, index) => {
                  return (
                    <li key={index} className="text-center font-bold">
                      <p
                        className={`text-white ${feature.color} py-1 md:px-6 px-1 text-sm md:text-xl`}
                      >
                        {feature.title}
                      </p>
                      <p className="text-sm md:text-xl bg-white py-1 md:px-6 px-3">
                        {feature.content}
                      </p>
                    </li>
                  )
                })}
              </ul>
              <h2 className="text-2xl md:text-3xl text-primary font-bold pb-2 border-b-2 border-primary md:w-3/4 mb-3">
                Recommended for
              </h2>
              <div className="">
                {recommends.map((recommend, index) => {
                  return (
                    <div key={index} className="flex items-center mb-1">
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-primary mr-3"
                      />
                      <p className="font-bold text-text md:text-xl">
                        {recommend.title}
                      </p>
                    </div>
                  )
                })}
              </div>
            </div>
          </section>
          {/* キャンペーンの特徴 */}
          <section className="campaign-feat bg-white">
            <div className="inner md:py-20 px-4 pt-8 pb-10">
              <h2 className="font-bold md:text-4xl text-2xl text-title text-center md:mb-10 mb-6">
                Campaign Features
              </h2>
              <div className="flex md:flex-row flex-col md:gap-10 gap-4">
                <div className="flex gap-4 flex-1">
                  <div className="border border-lightgrey flex-1">
                    <h3 className="py-2 font-bold bg-case_blue text-white text-center text-sm md:text-base">
                      Participant
                    </h3>
                    <div className="md:p-4 p-3">
                      <p className="font-bold text-sm text-text mb-1">
                        Diffusivity
                      </p>
                      <StarRates rate={5} />
                      <hr className="border-dashed border border-lightgrey my-4" />
                      <p className="font-bold text-sm text-text mb-1">
                        Ease of participation
                      </p>
                      <StarRates rate={5} />
                      <hr className="border-dashed border border-lightgrey my-4" />
                      <p className="font-bold text-sm text-text mb-1">
                        Motivation
                      </p>
                      <StarRates rate={5} />
                    </div>
                  </div>
                  <div className="border border-lightgrey flex-1">
                    <h3 className="py-2 font-bold bg-primary text-white text-center text-sm md:text-base">
                      Contact person
                    </h3>
                    <div className="md:p-4 p-3">
                      <p className="font-bold text-sm text-text mb-1">
                        Easiness
                      </p>
                      <StarRates rate={5} />
                    </div>
                  </div>
                </div>
                <p className="flex-1 text-text">
                  This is a very simple and effective campaign to increase
                  followers.
                  <br />
                  <br />
                  Users can participate with two clicks of "follow" and
                  "retweet," making it easy to participate and highly
                  diffusible.
                  <br />
                  <br />
                  You can send other companies' service codes such as Amazon
                  Gift Certificates, Quo Card Pay, etc., as well as your own
                  coupon codes to promote your company.
                  <br />
                  <br />
                  Users are highly motivated to participate because the digital
                  gift certificate codes are immediately sent to the winners via
                  DM.
                  <br />
                  <br />
                  DM sending is automatic, so once the campaign is launched,
                  there is no work for the person in charge.
                  <br />
                  The campaign tool is required to be implemented because the
                  winners must be notified as soon as they are followed and
                  retweeted to the participants.
                </p>
              </div>
            </div>
          </section>
          {/* 弊社でのキャンペーン実績 */}
          {/* <section className="campaign_results">
            <h2 className="title font-semibold text-center">弊社でのキャンペーン実績</h2>
            <div className="inner">
              <div className="left bg-white rounded">
                <h3 className="results_title text-xl leading-normal font-semibold mb-2">〇〇株式会社様</h3>
                <p className="date text-xs leading-normal">yyyy年mm月dd日〜yyyy年mm月dd日（dd日間）</p>
                <div className="img_box rounded">
                  <StaticImage
                    layout=""
                    alt=""
                    src=""
                  />
                </div>
                <ul className="detail_top flex justify-between">
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">リツイート数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">参加者数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">フォロワー増加数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                </ul>
                <ul className="detail_bottom">
                  <li className="text-center">
                    <h4 className="bottom_title text-sm leading-normal font-semibold py-1 mb-2"><span>*</span>CPF</h4>
                    <p className="bottom_description font-semibold mb-2">000円</p>
                  </li>
                </ul>
                <p className="caution text-xs leading-normal text-right"><span>*</span>フォロワー1人獲得当たりの単価</p>
              </div>
              <div className="right bg-white rounded">
                <h3 className="results_title text-xl leading-normal font-semibold mb-2">〇〇株式会社様</h3>
                <p className="date text-xs leading-normal">yyyy年mm月dd日〜yyyy年mm月dd日（dd日間）</p>
                <div className="img_box rounded">
                  <StaticImage
                    layout=""
                    alt=""
                    src=""
                  />
                </div>
                <ul className="detail_top flex justify-between">
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">リツイート数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">参加者数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                  <li className="text-center">
                    <h4 className="top_title text-xs leading-normal py-1 mb-2">フォロワー増加数</h4>
                    <p className="top_description text-xs leading-normal mb-3">000人</p>
                  </li>
                </ul>
                <ul className="detail_bottom">
                  <li className="text-center">
                    <h4 className="bottom_title text-sm leading-normal font-semibold py-1 mb-2"><span>*</span>CPF</h4>
                    <p className="bottom_description font-semibold mb-2">000円</p>
                  </li>
                </ul>
                <p className="caution text-xs leading-normal text-right"><span>*</span>フォロワー1人獲得当たりの単価</p>
              </div>
            </div>
          </section> */}
          {/* PARKLoTのキャンペーンツールを導入するとどう変わる？ */}
          <section className="before_after bg-secondary">
            <div className="inner pt-8 pb-10 md:py-20 px-4">
              <h2 className="font-bold md:text-4xl text-2xl text-title text-center md:mb-10 mb-6">
                How is this different from
                <br className="block" />a regular Follow &amp; Retweet campaign?
              </h2>
              <p className="mb-6 text-text">
                Instant Win requires the use of a campaign tool, and in fact,
                implementing a campaign tool can significantly reduce
                operational time compared to a manual follow &amp; retweet
                campaign!
                <br />
                <br />
                In addition, only winners can send their company's coupon code
                via DM, which can be used to promote their company's products.
              </p>
              <div className="flex flex-col md:flex-row md:gap-10 gap-4">
                <div className="flex-1 border border-primary rounded bg-white">
                  <h3 className="bg-primary font-bold text-xl leading-normal text-center text-white py-3">
                    If the campaign is conducted manually
                  </h3>
                  <div className="rounded-b p-4">
                    <div className="rounded-full text-center text-sm text-text border border-primary py-2">
                      Open the corresponding campaign page from Twitter
                    </div>
                    <div className="text-center">
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        className="text-lightgrey text-base"
                      />
                    </div>
                    <div className="rounded-full text-center text-sm text-text border border-primary py-2">
                      List of participants
                    </div>
                    <div className="text-center">
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        className="text-lightgrey text-base"
                      />
                    </div>
                    <div className="rounded py-4 bg-case_paleblue">
                      <div className="flex justify-center items-center mb-2">
                        <div className="text-center text-sm bg-white border border-primary rounded-full py-2 px-3">
                          Check user details screen
                        </div>
                        <div className="mx-2">
                          <FontAwesomeIcon
                            icon={faArrowRight}
                            className="text-lightgrey text-base"
                          />
                        </div>
                        <div className="text-center text-sm bg-white border border-primary rounded-full py-2 px-3">
                          Determination of winners
                        </div>
                      </div>
                      <p className="text-center text-sm font-bold text-primary">
                        Repeat for as many winners as there are winners
                      </p>
                    </div>
                    <div className="text-center">
                      <FontAwesomeIcon
                        icon={faArrowDown}
                        className="text-lightgrey text-base"
                      />
                    </div>
                    <div className="rounded py-4 bg-case_paleblue mb-4 text-center">
                      <div className="text-center inline-block text-sm bg-white border border-primary rounded-full py-2 px-3">
                        Search Winners' Accounts
                      </div>
                      <div className="text-center">
                        <FontAwesomeIcon
                          icon={faArrowDown}
                          className="text-lightgrey text-base"
                        />
                      </div>
                      <div className="text-center inline-block text-sm bg-white border border-primary rounded-full py-2 px-3 mb-2">
                        Creating, confirming, and sending DMs
                      </div>
                      <p className="text-center text-sm font-bold text-primary">
                        Repeat for as many winners as there are winners
                      </p>
                    </div>
                    <h3 className="text-center font-bold text-2xl mb-3 text-accent">
                      3-5 hours required
                      <br />
                      <span className="font-bold text-xl text-text">
                        just to complete the campaign
                      </span>
                    </h3>
                    <ul className="text-xs list-disc leading-normal py-4 pr-4 pl-8 bg-palegrey rounded">
                      <li>
                        Need to log in to Twitter and&nbsp;
                        <span className="font-bold">
                          check each participant's profile one by one
                        </span>
                      </li>
                      <li>
                        Need to&nbsp;
                        <span className="font-bold">
                          send DMs to winners one by one
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="border border-accent rounded md:mb-10 mb-4">
                    <h3 className="font-bold text-xl leading-normal bg-accent text-center text-white py-3">
                      When using PARKLoT
                    </h3>
                    <div className="bg-white rounded-b p-4">
                      <div className="rounded-full text-center text-sm text-text border border-accent py-2">
                        Register campaign data and digital gift certificate
                        codes from the administration screen
                      </div>
                      <div className="text-center">
                        <FontAwesomeIcon
                          icon={faArrowDown}
                          className="text-lightgrey text-base"
                        />
                      </div>
                      <div className="rounded-full text-center text-sm text-text border border-accent py-2">
                        Campaign automatically starts, and users who retweet are
                        automatically drawn
                      </div>
                      <div className="text-center">
                        <FontAwesomeIcon
                          icon={faArrowDown}
                          className="text-lightgrey text-base"
                        />
                      </div>
                      <div className="text-center text-sm font-bold text-accent bg-accent_pale rounded leading-normal py-4 mb-4">
                        When a winner is selected, we will automatically&nbsp;
                        <br />
                        send a DM with the digital gift certificate code
                      </div>
                      <h3 className="text-center text-text font-bold mb-3">
                        The campaign implementation process takes&nbsp;
                        <br />
                        <span className="text-2xl text-accent leading-normal">
                          20-30 minutes!
                        </span>
                      </h3>
                      <ul className="text-xs list-disc text-text leading-normal py-4 pr-4 pl-8 bg-palegrey rounded">
                        <li>
                          The list of participants&nbsp;
                          <span className="font-bold">
                            can be downloaded in CSV format&nbsp;
                          </span>
                          from the administration screen
                        </li>
                        <li>
                          The lottery process for winning and losing&nbsp;
                          <span className="font-bold">
                            can be fully automated
                          </span>
                        </li>
                        <li>
                          (As a secondary effect)
                          <span className="font-bold">
                            Analyze applicant trends and use them in future
                            campaigns
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="bottom border border-lightgrey rounded">
                    <h3 className="text-xl font-bold bg-lightgrey leading-normal text-center text-white py-3">
                      The tool is not recommended&nbsp;
                      <br />
                      in the following cases
                    </h3>
                    <div className="bg-white rounded-b py-4 pr-4 pl-8">
                      <ul className="font-bold text-text list-disc leading-normal md:p-4 p-1">
                        <li>No applicant information required</li>
                        <li>Fewer winners</li>
                        <li>No cost to implement the tool</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* 施策内容や見積もりについて、お気軽にご相談ください */}
          <CvBtn />
          {/* PARKLoTを使用した際の具体的なキャンペーン手順について */}
          <section className="usage_flow bg-white">
            <div className="inner md:py-20 pt-8 pb-10 px-4">
              <h2 className="font-bold md:text-4xl text-2xl text-title text-center md:mb-10 mb-6">
                Specific campaign procedures
                <br />
                when using PARKLoT
              </h2>
              <FlowNumber
                num="01"
                parklot={false}
                title="Please register your campaign information from the administration page"
              >
                <p className="text-text">
                  Please register your campaign information from the
                  administration page at least 3 business days prior to the
                  start of the campaign.
                  <br />
                  The required data can be found at&nbsp;
                  <a
                    className="text-accent underline"
                    href="https://xhackttl.notion.site/DM-c644052ee2d74a9986fea388ba70f0df"
                    target="_blank"
                    rel="noreferrer"
                  >
                    [Follow &amp; Retweet Campaign and Digital Gift Certificate
                    DM Distribution] Schedule and Required Data.
                  </a>
                </p>
              </FlowNumber>

              <FlowNumber
                num="02"
                parklot={false}
                title="Please provide a list of digital gift certificate codes in CSV format"
              >
                <p className="text-text">
                  Please provide a list of digital gift certificate codes in CSV
                  format at least 3 business days prior to the start of the
                  campaign.
                  <br />
                  We will link the data to the campaign data.
                </p>
              </FlowNumber>

              <FlowNumber
                num="03"
                parklot={true}
                title="The campaign tweet will be automatically posted and the campaign will begin"
              >
                <p className="text-text">
                  At the start time, the tweet will be automatically posted and
                  the campaign will begin.
                </p>
              </FlowNumber>

              <FlowNumber
                num="04"
                parklot={true}
                last={true}
                title="When we have a winner, we will automatically send you a DM to inform you of the digital code"
              >
                <p className="text-text">
                  Winners are automatically selected from retweeting users by
                  calculating the probability of winning.
                  <br />
                  When a winner is selected, we will automatically send a DM and
                  inform you of the digital gift certificate code.
                  <br />
                  <br />
                  The list of campaign participants can be viewed from the
                  administration page.
                </p>
              </FlowNumber>
            </div>
          </section>
          {/* PARKLoTの月額料金に含まれる機能は？ */}
          <section className="parklot_function bg-secondary">
            <div className="inner md:py-20 pt-8 pb-10 px-4">
              <h2 className="font-bold md:text-4xl text-2xl text-title text-center md:mb-10 mb-6">
                What features are included&nbsp;
                <br />
                in PARKLoT's monthly fee?
              </h2>
              <p className="text-text md:mb-10 mb-5">
                General Instant Win and Digital Gift Certificate Code sending
                campaigns are available within the monthly fee.
              </p>
              <h3 className="text-lg text-text font-bold text-center md:mb-6 mb-4">
                Features included in the monthly fee
              </h3>
              <div className="flex md:flex-row flex-col gap-4 md:gap-10 md:justify-around">
                <div className="p-4 md:p-6 text-center bg-white">
                  <StaticImage
                    layout="constrained"
                    alt="Instant Win Campaign Implementation Costs"
                    src="../../../../images/case_study/parklot_function01.png"
                    objectfit="contain"
                    className="w-28 mx-auto block mb-4 h-28"
                  />
                  <h4 className="font-bold text-text">
                    Instant Win Campaign&nbsp;
                    <br />
                    Implementation Costs
                  </h4>
                </div>
                <div className="p-4 md:p-6 text-center bg-white">
                  <StaticImage
                    layout="constrained"
                    alt=" CSV output of participant information"
                    src="../../../../images/case_study/parklot_function02.png"
                    objectfit="contain"
                    className="w-28 mx-auto block mb-4 h-28"
                  />
                  <h4 className="font-bold text-text mb-1">
                    CSV output of&nbsp;
                    <br />
                    participant information
                  </h4>
                  <p className="text-text text-xs">
                    *If complex customization is required,&nbsp;
                    <br />
                    an additional fee may be charged
                  </p>
                </div>
                <div className="p-4 md:p-6 text-center bg-white">
                  <StaticImage
                    layout="constrained"
                    alt="Automatic DM sending of digital gift certificate codes for automatically selected winners"
                    src="../../../../images/case_study/parklot_function03.png"
                    objectfit="contain"
                    className="w-28 mx-auto block mb-4 h-28"
                  />
                  <h4 className="font-bold text-text">
                    Automatic selection of winners
                    <br />
                    Automatic DM sending of&nbsp;
                    <br />
                    digital gift certificate codes
                  </h4>
                </div>
              </div>
            </div>
          </section>
          {/* 施策内容や見積もりについて、お気軽にご相談ください */}
          <CvBtn />
        </main>
      </Layout>
    </>
  )
}

export default NonInstantWinDigitalGift
